import "./style.css";
import * as THREE from "three";
import {
    OrbitControls
} from "three/examples/jsm/controls/OrbitControls.js";
import {
    GLTFLoader
} from "three/examples/jsm/loaders/GLTFLoader.js";
import {
    DRACOLoader
} from "three/examples/jsm/loaders/DRACOLoader.js";
import * as TWEEN from "@tweenjs/tween.js";
import gsap from "gsap";
import {
    AmbientLight,
    Camera,
    Color,
    Scene,
    Vector3
} from "three";
import {
    randFloat
} from "three/src/math/mathutils";
import $ from "jquery";

//////// Performance monitoring


//////// SCENE SETUP

let posIndex = 0;
let allowInput = true;
// Grab the canvas from the HTML file.
const canvas = document.querySelector("canvas.webgl");

// Create new ThreeJS scene
const scene = new THREE.Scene();
const sceneColor = new THREE.Color(0x000000);
scene.background = sceneColor;

//////// COLORS (should prbably hold a collection of colors to pick from)

let sceneColors = [
    new THREE.Color(0xb1bfbf),
    new THREE.Color(0xe9fbff),
    new THREE.Color(0xd61a00),
    new THREE.Color(0xff3d17),
    new THREE.Color(0xd9defe),
    new THREE.Color(0x250b0a),
    new THREE.Color(0xfec9b7),
    new THREE.Color(0x39a0ff),
    new THREE.Color(0xf4e4ff),
    new THREE.Color(0xeefcff),
    new THREE.Color(0xc7eafe),

];

let endCardColors = [
    new THREE.Color(0xe67dc0),
    new THREE.Color(0xafe7b9),
    new THREE.Color(0x8ae4fe),
    new THREE.Color(0x8ae4fe),
    new THREE.Color(0xe286ff),
    new THREE.Color(0x9a9999),
    new THREE.Color(0x9a9999),
    new THREE.Color(0x89dcff),
    new THREE.Color(0xd61900),
    new THREE.Color(0xc4bfe5),
];

let LevelColors = [];
let LevelOneColors = [
    new THREE.Color(0xb1bfbf),
    new THREE.Color(0x8eb0b2),
    new THREE.Color(0x7191fe),
    new THREE.Color(0xebb593),
    new THREE.Color(0xc2cccd),
    new THREE.Color(0xafbdbe),
    new THREE.Color(0xafbdbe),
];
LevelColors.push(LevelOneColors);

let LevelTwoColors = [
    new THREE.Color(0xe9fbff),
    new THREE.Color(0xe9fbff),
    new THREE.Color(0x003a0a),
    new THREE.Color(0xf1b0da),
    new THREE.Color(0xa0e281),
    new THREE.Color(0xfff8f5),
    new THREE.Color(0xe9fbff),
];
LevelColors.push(LevelTwoColors);

let LevelThreeColors = [
    new THREE.Color(0xd61a00),
    new THREE.Color(0xfdc4bd),
    new THREE.Color(0xc8c2e6),
    new THREE.Color(0xe8d9ae),
    new THREE.Color(0x8cf2c3),
    new THREE.Color(0xd61a00),
    new THREE.Color(0xfdc4bd),
    new THREE.Color(0xd61a00),
    new THREE.Color(0xfdc4bd),
];
LevelColors.push(LevelThreeColors);
let LevelFourColors = [
    new THREE.Color(0xff3d17),
    new THREE.Color(0xe5fe58),
    new THREE.Color(0xd7ff00),
    new THREE.Color(0x9788f1),
    new THREE.Color(0x3518e2),
    new THREE.Color(0x4025e4),
];
LevelColors.push(LevelFourColors);
let LevelFiveColors = [
    new THREE.Color(0xd9defe),
    new THREE.Color(0xf3ffe7),
    new THREE.Color(0xfffbff),
    new THREE.Color(0x583cff),
    new THREE.Color(0xd9defe),
    new THREE.Color(0xf3ffe7),
    new THREE.Color(0xd9defe),
    new THREE.Color(0xf3ffe7),
];
LevelColors.push(LevelFiveColors);
let LevelSixColors = [
    new THREE.Color(0x250b0a),
    new THREE.Color(0xe8ff8d),
    new THREE.Color(0xbbebff),
    new THREE.Color(0xf9ffe1),
    new THREE.Color(0xf3bfff),
    new THREE.Color(0x250b0a),
    new THREE.Color(0xe8ff8d),
];
LevelColors.push(LevelSixColors);
let LevelSevenColors = [
    new THREE.Color(0xfec9b7),
    new THREE.Color(0xfec9b7),
    new THREE.Color(0xfec9b7),
    new THREE.Color(0x9b0dff),
    new THREE.Color(0x43aa63),
    new THREE.Color(0xc6fff8),
    new THREE.Color(0x1f1f1f),
    new THREE.Color(0x3f0015),
];
LevelColors.push(LevelSevenColors);
let LevelEightColors = [
    new THREE.Color(0x39a0ff),
    new THREE.Color(0xbf9182),
    new THREE.Color(0x7f2407),
    new THREE.Color(0xdbae84),
    new THREE.Color(0x39a0ff),
    new THREE.Color(0x39a0ff),
    new THREE.Color(0x39a0ff),
    new THREE.Color(0x39a0ff),
];
LevelColors.push(LevelEightColors);
let LevelNineColors = [
    new THREE.Color(0xf4e4ff),
    new THREE.Color(0xff1f5b),
    new THREE.Color(0xff8fad),
    new THREE.Color(0x13ff17),
    new THREE.Color(0x9fbf80),
    new THREE.Color(0x012b43),
    new THREE.Color(0xf4e4ff),
    new THREE.Color(0xff1f5b),
    new THREE.Color(0xff8fad),
    new THREE.Color(0xf4e4ff),
    new THREE.Color(0xff1f5b),
    new THREE.Color(0xff8fad),
];
LevelColors.push(LevelNineColors);
let LevelTenColors = [
    new THREE.Color(0xeefcff),
    new THREE.Color(0xfeb07f),
    new THREE.Color(0xfe6100),
    new THREE.Color(0x573519),
    new THREE.Color(0xfff1e8),
    new THREE.Color(0xeefcff),
    new THREE.Color(0xeefcff),
];
LevelColors.push(LevelTenColors);

let LevelElevenColors = [
    new THREE.Color(0xc7eafe),
    new THREE.Color(0x3e89ff),
    new THREE.Color(0xddfafe),
    new THREE.Color(0xc7eafe),
    new THREE.Color(0xc7eafe),

];
LevelColors.push(LevelElevenColors);
//////// FOG

const fogNear = 50;
const fogFar = 120;
scene.fog = new THREE.Fog(sceneColor, fogNear, fogFar);


//scene.background = environmentMapTexture;

//////// LIGHTS

const ambientLight = new THREE.AmbientLight(0xffffff, 2); // soft white light
scene.add(ambientLight);

const directionalLight = new THREE.DirectionalLight("#ff00f0", 3);
directionalLight.position.set(-10, 15, 0);
directionalLight.castShadow = true;
directionalLight.shadow.camera.near = 1;
directionalLight.shadow.camera.far = 200;
directionalLight.shadow.camera.top = 60;
directionalLight.shadow.camera.right = 60;
directionalLight.shadow.camera.bottom = -60;
directionalLight.shadow.camera.left = -60;
directionalLight.shadow.radius = 5;
directionalLight.shadow.mapSize.width = 1024;
directionalLight.shadow.mapSize.height = 1024;
scene.add(directionalLight);

const firstLightTarget = new THREE.Object3D();
firstLightTarget.position.set(10, 0, -25);
scene.add(firstLightTarget);
directionalLight.target = firstLightTarget;

const secondDirectionalLight = new THREE.DirectionalLight("#00d8ff", 3);
secondDirectionalLight.position.set(10, 15, 0);
secondDirectionalLight.castShadow = true;
secondDirectionalLight.shadow.camera.near = 1;
secondDirectionalLight.shadow.camera.far = 200;
secondDirectionalLight.shadow.camera.top = 60;
secondDirectionalLight.shadow.camera.right = 60;
secondDirectionalLight.shadow.camera.bottom = -60;
secondDirectionalLight.shadow.camera.left = -60;
secondDirectionalLight.shadow.radius = 5;
secondDirectionalLight.shadow.mapSize.width = 1024;
secondDirectionalLight.shadow.mapSize.height = 1024;
scene.add(secondDirectionalLight);

const secondLightTarget = new THREE.Object3D();
secondLightTarget.position.set(-10, 0, -25);
scene.add(secondLightTarget);
secondDirectionalLight.target = secondLightTarget;



//////// LOADERS

// Texture loader
const textureLoader = new THREE.TextureLoader();

// Draco loader (compresses the scene if you need it)
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath("draco/");

// GLTF loader
const gltfLoader = new GLTFLoader();
gltfLoader.setDRACOLoader(dracoLoader);

//////// RESPONSIVE WINDOW SIZES

const sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
};

window.addEventListener("resize", () => {
    // Update sizes
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;

    // Update camera
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    // Update renderer
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

//////// CAMERA

const camera = new THREE.PerspectiveCamera(
    40,
    sizes.width / sizes.height,
    0.1,
    1000
);
camera.position.x = 0;
camera.position.y = 10;
camera.position.z = 10;

scene.add(camera);

//////// MOVE CAMERA FORWARD

let cameraSpeed = 3;

function MoveCamera() {
    camera.position.z -= cameraSpeed;
}

let enableBarrellRoll = false;

function CameraBarrellRoll() {
    if (enableBarrellRoll) camera.rotation.z += 0.01;
}
//////// CAMERA FOLLOW MOUSE FUNCTIONALITY

const mouse = new THREE.Vector2();
const mouseTarget = new THREE.Vector2();
const windowHalf = new THREE.Vector2(
    window.innerWidth / 2,
    window.innerHeight / 2
);

let enableRotation = false;
window.addEventListener("mousemove", onMouseMove, false);

function onMouseMove(event) {
    mouse.x = event.clientX - windowHalf.x;
    mouse.y = event.clientY - windowHalf.y;
}

let introCube;
let introTitle;

function MovingMouse() {
    mouseTarget.x = (1 - mouse.x) * 0.002;
    mouseTarget.y = (1 - mouse.y) * 0.002;
    if (enableRotation) {
        camera.rotation.x += 0.01 * (mouseTarget.y - camera.rotation.x);
        camera.rotation.y += 0.01 * (mouseTarget.x - camera.rotation.y);
    }

    if (introCube != null) {
        introCube.rotation.x += 0.5 * (mouseTarget.y - introCube.rotation.x);
        introCube.rotation.y += 0.5 * (mouseTarget.x - introCube.rotation.y);
    }

    if (introTitle != null) {
        introTitle.rotation.x -= 0.5 * (mouseTarget.y + introTitle.rotation.x);
        introTitle.rotation.y -= 0.5 * (mouseTarget.x + introTitle.rotation.y);
    }
}

//////// CAMERA CONTROL VIA DEVICE ORIENTATION

window.addEventListener("deviceorientation", function (e) {
    var deviceRotation = e.gamma ? e.gamma * (Math.PI / 600) : 0;
    camera.rotation = deviceRotation;
});

//////// ORBIT CAMERA CONTROLS (for testing)
/*
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;
controls.enablePan = false;
controls.enableZoom = true;
*/

//////// AUDIO

// create an AudioListener and add it to the camera
const listener = new THREE.AudioListener();
camera.add(listener);

// create a global audio source
const sound = new THREE.Audio(listener)

// load a sound and set it as the Audio object's buffer
const audioLoader = new THREE.AudioLoader();;
let currentlyPlaying;
let soundA
let soundB
let soundC
let soundD
let soundE
let soundF
let soundG
let soundH
let soundI
let soundJ
let soundK

//////// RENDERER

const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
renderer.physicallyCorrectLights = true;
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFShadowMap;

//////// TEXTURES
let trackTextures = [];
const trackTextureA = textureLoader.load("/abstracttextures/01-Baynk-TouchMe(HoldMyHand).jpg");
trackTextures.push(trackTextureA)
const trackTextureB = textureLoader.load("/abstracttextures/02-Baynk-Remember.jpg");
trackTextures.push(trackTextureB)
trackTextureB.name = "TextureB"
const trackTextureC = textureLoader.load("/abstracttextures/03-Baynk-Esther.jpg");
trackTextures.push(trackTextureC)
const trackTextureD = textureLoader.load("/abstracttextures/04-Baynk-CloudControl.jpg");
trackTextures.push(trackTextureD)
const trackTextureE = textureLoader.load("/abstracttextures/05-Baynk-OneChance.jpg");
trackTextures.push(trackTextureE)
const trackTextureF = textureLoader.load("/abstracttextures/06-Baynk-StateOfMind.jpg");
trackTextures.push(trackTextureF)
const trackTextureG = textureLoader.load("/abstracttextures/07-Baynk-Mine.jpg");
trackTextures.push(trackTextureG)
const trackTextureH = textureLoader.load("/abstracttextures/08-Baynk-WhatIfHePutHisHandOnYou.jpg");
trackTextures.push(trackTextureH)
const trackTextureI = textureLoader.load("/abstracttextures/09-Baynk-Naked.jpg");
trackTextures.push(trackTextureI)
const trackTextureJ = textureLoader.load("/abstracttextures/10-Baynk-TillItsEven.jpg");
trackTextures.push(trackTextureJ)
const trackTextureK = textureLoader.load("/abstracttextures/11-Baynk-WhenImAlone.jpg");
trackTextures.push(trackTextureK)

//////// CORRIDOR TEXTURES

let corridorTextures = [];


const corridorTextureA = textureLoader.load("/abstracttextures/01-Baynk-TouchMe(HoldMyHand).jpg");
corridorTextures.push(corridorTextureA)
const corridorTextureB = textureLoader.load("/abstracttextures/02-Baynk-Remember.jpg");
corridorTextures.push(corridorTextureB)
corridorTextureB.name = "TextureB"
const corridorTextureC = textureLoader.load("/abstracttextures/03-Baynk-Esther.jpg");
corridorTextures.push(corridorTextureC)
const corridorTextureD = textureLoader.load("/abstracttextures/04-Baynk-CloudControl.jpg");
corridorTextures.push(corridorTextureD)
const corridorTextureE = textureLoader.load("/abstracttextures/05-Baynk-OneChance.jpg");
corridorTextures.push(corridorTextureE)
const corridorTextureF = textureLoader.load("/abstracttextures/06-Baynk-StateOfMind.jpg");
corridorTextures.push(corridorTextureF)
const corridorTextureG = textureLoader.load("/abstracttextures/07-Baynk-Mine.jpg");
corridorTextures.push(corridorTextureG)
const corridorTextureH = textureLoader.load("/abstracttextures/08-Baynk-WhatIfHePutHisHandOnYou.jpg");
corridorTextures.push(corridorTextureH)
const corridorTextureI = textureLoader.load("/abstracttextures/09-Baynk-Naked.jpg");
corridorTextures.push(corridorTextureI)
const corridorTextureJ = textureLoader.load("/abstracttextures/10-Baynk-TillItsEven.jpg");
corridorTextures.push(corridorTextureJ)
const corridorTextureK = textureLoader.load("/abstracttextures/11-Baynk-WhenImAlone.jpg");
corridorTextures.push(corridorTextureK)
/*

const newCorridorTextureI = textureLoader.load("/abstracttextures/Baynk_Bg_Touch Me.jpg");
newCorridorTextureI.anisotropy = 16
corridorTextures.push(newCorridorTextureI)

const newCorridorTextureF = textureLoader.load("/abstracttextures/Baynk_Bg_Remember.jpg");
newCorridorTextureF.anisotropy = 16
corridorTextures.push(newCorridorTextureF)

const newCorridorTextureA = textureLoader.load("/abstracttextures/Esther_Background.png");
newCorridorTextureA.anisotropy = 16
corridorTextures.push(newCorridorTextureA)

const newCorridorTextureB = textureLoader.load("/abstracttextures/Baynk_Bg_Cloud Control.jpg");
newCorridorTextureB.anisotropy = 16
corridorTextures.push(newCorridorTextureB)

const newCorridorTextureE = textureLoader.load("/abstracttextures/Baynk_Bg_One Chance.jpg");
newCorridorTextureE.anisotropy = 16
corridorTextures.push(newCorridorTextureE)

const newCorridorTextureG = textureLoader.load("/abstracttextures/Baynk_Bg_State Of Mine.jpg");
newCorridorTextureG.anisotropy = 16
corridorTextures.push(newCorridorTextureG)

const newCorridorTextureC = textureLoader.load("/abstracttextures/Baynk_Bg_Mine.jpg");
newCorridorTextureC.anisotropy = 16
corridorTextures.push(newCorridorTextureC)

const newCorridorTextureJ = textureLoader.load("/abstracttextures/Baynk_Bg_What If.jpg");
newCorridorTextureJ.anisotropy = 16
corridorTextures.push(newCorridorTextureJ)

const newCorridorTextureD = textureLoader.load("/abstracttextures/Baynk_Bg_Naked.jpg");
newCorridorTextureD.anisotropy = 16
corridorTextures.push(newCorridorTextureD)

const newCorridorTextureH = textureLoader.load("/abstracttextures/Baynk_Bg_Til Its Even.jpg");
newCorridorTextureH.anisotropy = 16
corridorTextures.push(newCorridorTextureH)

const newCorridorTextureK = textureLoader.load("/abstracttextures/Baynk_Bg_When Im Alone.jpg");
newCorridorTextureK.anisotropy = 16
corridorTextures.push(newCorridorTextureK)
*/
console.log("cori tex = " + corridorTextures.length)


let cubeTextures = [];

const cubeTextureA = trackTextures[0];
cubeTextures.push(cubeTextureA);

const introCubeHoverTex = textureLoader.load(
    "/abstracttextures/Baynk-Adolescence-AlbumCover-3000x3000.jpg"
);
introCubeHoverTex.wrapS = THREE.RepeatWrapping;
introCubeHoverTex.wrapT = THREE.RepeatWrapping;
introCubeHoverTex.repeat.set(1, 1);

const endCardTex = textureLoader.load(
    "/abstracttextures/baynkTexA_lrgBW.jpg"
);

SetUpTextures();

function SetUpTextures() {
    for (let i = 0; i < trackTextures.length; i++) {
        trackTextures[i].wrapS = THREE.RepeatWrapping;
        trackTextures[i].wrapT = THREE.RepeatWrapping;
        trackTextures[i].repeat.set(25, 25);

    }
    for (let i = 0; i < cubeTextures.length; i++) {
        cubeTextures[i].wrapS = THREE.RepeatWrapping;
        cubeTextures[i].wrapT = THREE.RepeatWrapping;
        cubeTextures[i].repeat.set(1, 1);
    }
    for (let i = 0; i < corridorTextures.length; i++) {

        corridorTextures[i].wrapS = THREE.RepeatWrapping;
        corridorTextures[i].wrapT = THREE.RepeatWrapping;
        corridorTextures[i].repeat.set(25, 25);

    }

    console.log(corridorTextures.length)
}

//////// MATERIALS

const corridorMaterialA = new THREE.MeshBasicMaterial();

const endCardMatA = new THREE.MeshStandardMaterial();

const endCardMatB = new THREE.MeshStandardMaterial();

const transparentMaterial = new THREE.MeshStandardMaterial();
transparentMaterial.transparent = true;

let cubeMaterials = [];

const cubeMaterialA = new THREE.MeshBasicMaterial();
cubeMaterialA.color = new THREE.Color(0x272727);
cubeMaterials.push(cubeMaterialA);

const introCubeMat = new THREE.MeshStandardMaterial();
let introCubeOriginalColor = new THREE.Color(0x272727);
introCubeMat.metalness = 0.5;
introCubeMat.roughness = 0.2;
introCubeMat.color = introCubeOriginalColor;
introCubeMat.transparent = true;

const introCubeHoverMat = new THREE.MeshBasicMaterial();
introCubeHoverMat.map = introCubeHoverTex;
introCubeHoverMat.color = new THREE.Color(0xffffff);
introCubeHoverMat.transparent = true;


//////// PRE-LOAD GEOMETRY
let loadedCorridors = [];

const geometry = new THREE.PlaneGeometry(1000, 1000);
const planeA = new THREE.Mesh(geometry, corridorMaterialA);
planeA.position.y = 5;
planeA.position.z = -100;
planeA.rotation.x = -Math.PI / 2;
scene.add(planeA);
planeA.visible = false;
loadedCorridors.push(planeA);

const planeB = planeA.clone();
planeB.rotation.x = Math.PI / 2;
planeB.position.y = 20;
scene.add(planeB);
planeB.visible = false;
loadedCorridors.push(planeB);

const planeC = planeA.clone();
planeC.rotation.y = Math.PI / 2;
planeC.position.x = -20;
scene.add(planeC);
planeC.visible = false;
loadedCorridors.push(planeC);

const planeD = planeA.clone();
planeD.rotation.y = -Math.PI / 2;
planeD.position.x = 20;
scene.add(planeD);
planeD.visible = false;
loadedCorridors.push(planeD);

//////// ANIMATIONS

let enableUVSwitching
let enableVisibilitySwitching
let enableAnimationMovement;
let enableCorridorUVScrolling
let enableObjectUVScrolling

function ApplyRandomUVCoordinates() {

    if (enableUVSwitching) {
        corridorTextures[sceneIndex - 1].offset.y += GetRandomFloat(.2, 1);
        console.log("UV texture timer firing!")

        trackTextures[sceneIndex - 1].offset.y += GetRandomFloat(.2, 1);
        /////////////////////////////////////////////////////////////////////////////////////////////why isnt this working?
    }
}

let uvScrollSpeed = 0.01
let scrollOnXAxis = true

function ApplyUVScrolling() {


    if (enableCorridorUVScrolling) {

        if (scrollOnXAxis) {
            if (loadedCorridors.length > 0) {
                for (let i = 0; i < loadedCorridors.length; i++) {
                    loadedCorridors[i].material.map.offset.x += uvScrollSpeed
                }
            }
        } else {
            if (loadedCorridors.length > 0) {
                for (let i = 0; i < loadedCorridors.length; i++) {
                    loadedCorridors[i].material.map.offset.y += uvScrollSpeed
                }
            }
        }

    }

}

function ApplyObjectVisbility() {

    if (enableVisibilitySwitching) {
        for (let i = 0; i < segments.length; i++) {
            let randomInt = getRandomInt(0, 20);

            if (randomInt > 18) {
                segments[i].visible = false;
            }

            if (randomInt < 3) {
                let colors = LevelColors[sceneIndex - 1];
                let randColor = colors[getRandomInt(0, colors.length - 1)];
                if (segments[i].isMesh) {
                    segments[i].material.color = randColor;
                }
                segments[i].visible = true;
            }
        }
    }

}

function ApplyRandomLocation() {
    for (let i = 0; i < segments.length; i++) {

        segments[i].position.set(segments[i].position.x + GetRandomFloat(-1, 1), segments[i].position.y + GetRandomFloat(-1, 1), segments[i].position.z + GetRandomFloat(-1, 1))
    }
}

let animationMovementDistance = 1

function ApplyRandomMovementToCubes(arrayToMove) {

    if (enableAnimationMovement == true) {
        for (let i = 0; i < arrayToMove.length; i++) {

            arrayToMove[i].position.z += GetRandomFloat(-.1, .1)

            if (Math.random() < 0.5) {
                gsap.to(arrayToMove[i].position, {
                    duration: 1,
                    delay: 1,

                    y: arrayToMove[i].position.y + getRandomInt(-animationMovementDistance, animationMovementDistance),
                });
            } else {
                gsap.to(arrayToMove[i].position, {
                    duration: 1,
                    delay: 1,

                    x: arrayToMove[i].position.x + getRandomInt(-animationMovementDistance, animationMovementDistance),
                });
            }
        }
    }

}

function ApplyRandomRotationToCubes() {
    for (let i = 0; i < segments.length; i++) {
        segments[i].rotation.x = -Math.PI //getRandomRotation()
    }
}

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getRandomRotation() {
    var rots = [Math.PI, -Math.PI, Math.PI / 2, -Math.PI / 2];
    return rots[getRandomInt(0, 4)];
}

//////// LOAD INTRODUCTION SCENE
let sceneIndex;

let one_bgPlane;
let one_bgPlaneB;
let one_bgPlaneC;
let redLight;
let enableCubeColorSwitch;
let logoOriginalMat = new THREE.MeshBasicMaterial();
logoOriginalMat.color = new THREE.Color(0x000000)

LoadSceneOne();

function LoadSceneOne() {
    scene.background = new THREE.Color(0xffffff) // sceneColors[getRandomInt(0, sceneColors.length - 1)];
    scene.fog.color = scene.background;
    cameraSpeed = 0;
    sceneIndex = 0;

    const bgPlaneMaterial = new THREE.MeshBasicMaterial();
    bgPlaneMaterial.color = new THREE.Color(0xffffff);


    const one_bgGeometry = new THREE.PlaneGeometry(25, 25);
    one_bgPlane = new THREE.Mesh(one_bgGeometry, bgPlaneMaterial);
    one_bgPlane.name = "BG Plane";
    one_bgPlane.position.y = 22;
    one_bgPlane.position.z = -2;
    one_bgPlane.rotation.z = Math.PI / 4;
    one_bgPlane.callback = function () {
        enableCubeColorSwitch = false;
        introTitle.material = logoOriginalMat;
    };
    scene.add(one_bgPlane);

    one_bgPlaneB = new THREE.Mesh(one_bgGeometry, bgPlaneMaterial);
    one_bgPlaneB.name = "BG Plane";
    one_bgPlaneB.position.x = 15.2;
    one_bgPlaneB.position.y = 1.75;
    one_bgPlaneB.position.z = -2;
    one_bgPlaneB.rotation.z = Math.PI / 4;
    one_bgPlaneB.callback = function () {
        enableCubeColorSwitch = false;
        introTitle.material = logoOriginalMat;
    };
    scene.add(one_bgPlaneB);

    one_bgPlaneC = new THREE.Mesh(one_bgGeometry, bgPlaneMaterial);
    one_bgPlaneC.name = "BG Plane";
    one_bgPlaneC.position.x = -15.2;
    one_bgPlaneC.position.y = 1.75;
    one_bgPlaneC.position.z = -2;
    one_bgPlaneC.rotation.z = Math.PI / 4;
    one_bgPlaneC.callback = function () {
        enableCubeColorSwitch = false;
        introTitle.material = logoOriginalMat;
    };
    scene.add(one_bgPlaneC);

    let newCube = new THREE.Mesh(new THREE.PlaneGeometry(2, 2, 2), introCubeMat);
    let switchIndex = 0;
    newCube.position.set(0, 10, -.5);
    //newCube.rotation.z = Math.PI / 4;
    newCube.name = "Intro cube";
    newCube.callback = function () {
        if (switchIndex == 0) {
            enableCubeColorSwitch = true;
        }
    };
    introCube = newCube;
    scene.add(newCube);

    /*  redLight = new THREE.PointLight(0xff0000, 100, 1000);
    redLight.position.set(0, 10, -1);
    scene.add(redLight);
*/
    gltfLoader.load("logob.gltf", (logo) => {
        logo.scene.traverse((child) => {
            child.material = logoOriginalMat;

            child.position.set(0, 6.5, -0.5);
            child.name = "logo";
            introTitle = child;

        });

        scene.add(logo.scene);
    });
}

let matIndex = 0;
let experienceStarted = false;

function ChangeCubeColorsOnHover() {
    introCube.material = introCubeHoverMat;
    introCubeHoverTex.offset.y += .01;

    /*
    if (!experienceStarted) {
        if (enableCubeColorSwitch) {
            introCube.material = introCubeHoverMat;
            introCubeHoverTex.offset.y += .01;

            // introCubeHoverTex.offset.z += GetRandomFloat(0, 1);
            // introCubeHoverTex.repeat.set(GetRandomFloat(1, 2));
            // introCubeHoverTex.rotation = getRandomRotation();

            introTitle.material = introCubeHoverMat;
        } else {
            introCube.material = introCubeMat;
        }
    }*/
}
/////// LOAD MAIN SCENE

let cubeCloud = [];
let entertoendObject;
let segments = [];

function LoadMainScene() {
    sceneIndex++;
    enableRotation = true;
    cameraSpeed = 0.1;
    //   directionalLight.intensity = 0;
    //  secondDirectionalLight.intensity = 0;
    ambientLight.intensity = 3;
    //  redLight.intensity = 0;
    scene.background = sceneColors[sceneIndex];
    scene.fog.color = scene.background;

    for (let i = 0; i < loadedCorridors.length; i++) {
        loadedCorridors[i].visible = true;
        loadedCorridors[i].material = cubeMaterialA.clone()
        loadedCorridors[i].material.map = corridorTextures[sceneIndex - 1];
        loadedCorridors[i].material.color = new THREE.Color(0xffffff)
    }

    //////// CREATE CUBE GRID (for testing)

    let cubeGridCount = 100;

    for (let v = 0; v < cubeGridCount; v += 1) {
        let newCubeGeometry = new THREE.PlaneGeometry(
            getRandomInt(3, 20),
            getRandomInt(3, 20)

        );
        let newCube = new THREE.Mesh(newCubeGeometry, cubeMaterialA);
        newCube.material.map = trackTextureA
        newCube.material.color = new Color(0xffffff)
        let randRots = [Math.PI, Math.PI / 2, -Math.PI, -Math.PI / 2];
        let size = GetRandomFloat(1, 5);
        newCube.size = new THREE.Vector3(size, size, size)
        newCube.position.x = getRandomInt(-20, 20);
        newCube.position.z = getRandomInt(-20, -150) + GetRandomFloat(-1, 1);
        newCube.position.y = getRandomInt(-5, 20);
        newCube.position.z = newCube.position.z + GetRandomFloat(-1, 1)
        newCube.rotation.z = randRots[getRandomInt(0, 3)];
        newCube.visible = true;

        newCube.traverse(function (child) {
            //  var distance = child.position.distanceTo(new Vector3(0, 0, child.position.z));

            let randomInt = getRandomInt(0, 20);
            segments.push(child);
            if (randomInt > 10) {
                child.visible = false;
            } else {


            }
        });

        cubeCloud.push(newCube);
        scene.add(newCube);
    }

    LoadLevelObjects(cubeCloud);

    gltfLoader.load("entertoend.gltf", (entertoend) => {
        entertoend.scene.traverse((child) => {
            child.material = new THREE.MeshStandardMaterial();
            child.material.color = new THREE.Color(0xfffff);
            child.material.transparent = true;
            camera.add(child);
            child.position.set(0, -0.3, -1);
            entertoendObject = child;
            child.name = "entertoend";


        });


        scene.add(entertoend.scene);
    });

    LoadMusic("/audio/BAYNK-1min.mp3")
    ApplySceneParameters()
    console.log(segments.length)
}


//////// GENERATING AND DESTROYING DYNAMIC OBJECTS

let loadedObjects = [];
let objectOriginalLocations = [];

function LoadLevelObjects(arrayOfObjects) {
    for (let i = 0; i < loadedObjects.length; i++) {
        loadedObjects[i].visible = false;
    }

    loadedObjects = [];
    objectOriginalLocations = [];

    for (let i = 0; i < arrayOfObjects.length; i++) {
        arrayOfObjects[i].visible = true;
        loadedObjects.push(arrayOfObjects[i]);
        objectOriginalLocations.push(arrayOfObjects[i].position);
    }
}

function RecycleObjects() {
    if (loadedObjects.length > 0) {
        for (let i = 0; i < loadedObjects.length; i++) {
            if (loadedObjects[i].position.z >= camera.position.z) {
                loadedObjects[i].position.z =
                    camera.position.z - getRandomInt(150, 250) + GetRandomFloat(-1, 1);
                loadedObjects[i].material.opacity = 1
                loadedObjects[i].material.transparent = false
            } else {}
        }
    }

    if (loadedCorridors.length > 0) {
        for (let i = 0; i < loadedCorridors.length; i++) {
            if (loadedCorridors[i].position.z >= camera.position.z) {
                loadedCorridors[i].position.z = camera.position.z - 350; // objectOriginalLocations[i].z
            }
        }
    }
}


//////// CREATE END CARD

let endcards = [];
let didyoulikeitObject;
let didyoulikeitsadObject;
let didyoulikeithappyObject;
let endCardActive = false;

gltfLoader.load("didyoulikeit.gltf", (entertoend) => {
    entertoend.scene.traverse((child) => {
        child.material = new THREE.MeshStandardMaterial();
        child.material.color = new THREE.Color(0xffffff);
        child.material.transparent = true;
        child.material.opacity = 0;
        camera.add(child);
        child.position.set(0, 0.2, -1);
        didyoulikeitObject = child;
        child.name = "didyoulikeit";
    });


    scene.add(entertoend.scene);
});

gltfLoader.load("didyoulikeit_sad.gltf", (didyoulikeit_sad) => {
    didyoulikeit_sad.scene.traverse((child) => {
        child.material = new THREE.MeshStandardMaterial();
        child.material.color = new THREE.Color(0xffffff);
        child.material.transparent = true;
        child.material.opacity = 0;
        camera.add(child);
        child.position.set(-0.2, -0.1, -1);
        didyoulikeitsadObject = child;
        child.name = "didyoulikeitsad";
    });
    scene.add(didyoulikeit_sad.scene);
});

gltfLoader.load("didyoulikeit_happy.gltf", (didyoulikeit_happy) => {
    didyoulikeit_happy.scene.traverse((child) => {
        child.material = new THREE.MeshStandardMaterial();
        child.material.color = new THREE.Color(0xffffff);
        child.material.transparent = true;
        child.material.opacity = 0;
        camera.add(child);
        child.position.set(0.2, -0.1, -1);
        didyoulikeithappyObject = child;
        child.name = "didyoulikeit_happy";
    });

    scene.add(didyoulikeit_happy.scene);
});

function CreateNewEndCard() {

    if (!endCardActive) {
        let endCardBoxAMesh;
        let endCardBoxBMesh;

        let tween = new TWEEN.Tween(listener.gain.gain).to({
                    value: 0,
                },
                1000
            ).onComplete(function () {
                currentlyPlaying.stop();
            })
            .start()

        if (endcards.length == 0) {
            let endCardSize = 5;
            const endCardBoxA = new THREE.BoxGeometry(
                endCardSize,
                endCardSize,
                endCardSize
            );
            endCardBoxAMesh = new THREE.Mesh(endCardBoxA, endCardMatA);
            scene.add(endCardBoxAMesh);
            camera.add(endCardBoxAMesh);
            endcards.push(endCardBoxAMesh);

            const endCardBoxB = new THREE.BoxGeometry(
                endCardSize,
                endCardSize,
                endCardSize
            );
            endCardBoxBMesh = new THREE.Mesh(endCardBoxB, endCardMatB);
            scene.add(endCardBoxBMesh);
            camera.add(endCardBoxBMesh);
            endcards.push(endCardBoxBMesh);
        }

        endCardBoxAMesh = endcards[0];
        endCardBoxBMesh = endcards[1];

        endCardBoxAMesh.position.y = 0;
        endCardBoxAMesh.position.z = -8;
        endCardBoxAMesh.position.x = 20;
        endCardBoxAMesh.rotation.x = -Math.PI / 2;

        endCardTex.repeat.set(getRandomInt(1, 4), getRandomInt(1, 4));
        endCardTex.repeat.set(6, 6);
        endCardTex.rotation = getRandomRotation();
        endCardMatA.map = endCardTex;
        endCardMatA.color = endCardColors[getRandomInt(0, endCardColors.length - 1)];

        endCardBoxBMesh.position.y = 0;
        endCardBoxBMesh.position.z = -8;
        endCardBoxBMesh.position.x = -20;
        endCardBoxBMesh.rotation.x = -Math.PI / 2;

        endCardMatB.map = endCardTex;
        endCardMatB.color = endCardColors[getRandomInt(0, endCardColors.length - 1)];

        gsap.to(endCardBoxBMesh.position, {
            duration: 1,
            delay: 0,
            x: -2.5,
            y: 0,
            z: -8,
        });

        gsap.to(endCardBoxAMesh.position, {
            duration: 1,
            delay: 0,
            x: 2.5,
            y: 0,
            z: -8,
        });

        for (let i = 0; i < endcards.length; i++) {
            endcards[i].callback = function () {

                DestroyEndCards();
            };
        }

        gsap.to(entertoendObject.material, {
            duration: 1,
            delay: 0,

            opacity: 0,
        });

        gsap.to(didyoulikeitObject.material, {
            duration: 1,
            delay: 0,

            opacity: 1,
        });
        gsap.to(didyoulikeitsadObject.material, {
            duration: 1,
            delay: 0,

            opacity: 1,
        });
        gsap.to(didyoulikeithappyObject.material, {
            duration: 1,
            delay: 0,

            opacity: 1,
        });

        setTimeout(() => {
            cameraSpeed = 0;
            enableRotation = false;

        }, 1000);

        endCardActive = true;
    }

}

function DestroyEndCards() {
    SwitchScene();
    enableRotation = true;

    let endCardA = endcards[0];
    let endCardB = endcards[1];

    gsap.to(endCardA.position, {
        duration: 1,
        delay: 0,
        x: 20,
        y: 0,
        z: -8,
    });

    gsap.to(endCardB.position, {
        duration: 1,
        delay: 0.3,
        x: -20,
        y: 0,
        z: -8,
    });

    gsap.to(entertoendObject.material, {
        duration: 1,
        delay: 0,

        opacity: 1,
    });
    gsap.to(didyoulikeitObject.material, {
        duration: 1,
        delay: 0,

        opacity: 0,
    });

    gsap.to(didyoulikeitsadObject.material, {
        duration: 1,
        delay: 0,

        opacity: 0,
    });
    gsap.to(didyoulikeithappyObject.material, {
        duration: 1,
        delay: 0,

        opacity: 0,
    });

    endCardActive = false;
}

function InitializeExperience() {
    gsap.to(one_bgPlane.position, {
        duration: 2,
        delay: 0,
        x: 0,
        y: 35,
        z: 0,
    });

    gsap.to(one_bgPlaneB.position, {
        duration: 2,
        delay: 0,
        x: 35,
        y: 0,
        z: 0,
    });

    gsap.to(one_bgPlaneC.position, {
        duration: 2,
        delay: 0,
        x: -35,
        y: 0,
        z: 0,
    });

    experienceStarted = true;
    introCube.material = new THREE.MeshBasicMaterial();
    introCube.material.color = new THREE.Color(0xffffff);
    introCube.material.transparent = true;

    gsap.to(introCube.material, {
        duration: 1,
        delay: 0,

        opacity: 0,
    });

    cameraSpeed = 0.2;
    LoadMainScene();

    setTimeout(() => {
        cameraSpeed = 1;

    }, 1000);
}

//////// SWITCHING SCENES

let corridorUVSizeX = 1
let corridorUVSizeY = 100
let objectUVSize = 25

function SwitchScene() {
    sceneIndex++;
    console.log("Scene Index = " + sceneIndex)
    SwitchMusic();
    ApplySceneParameters();
    console.log(segments.length)
    if (sceneIndex > sceneColors.length) sceneIndex = 1;

    let colors = LevelColors[sceneIndex - 1];

    for (let i = 0; i < loadedCorridors.length; i++) {
        loadedCorridors[i].material.map = corridorTextures[sceneIndex - 1];
        loadedCorridors[i].material.map.repeat.set(corridorUVSizeX, corridorUVSizeY);

    }

    for (let i = 0; i < cubeCloud.length; i++) {
        cubeCloud[i].material.map = trackTextures[sceneIndex - 1]
        cubeCloud[i].material.color = new THREE.Color(0xffffff)
        cubeCloud[i].material.map.needsUpdate = true;
        cubeCloud[i].material.map.wrapS = THREE.RepeatWrapping;
        cubeCloud[i].material.map.wrapT = THREE.RepeatWrapping;
        cubeCloud[i].material.map.repeat.set(objectUVSize, objectUVSize);
    }

    for (let i = 0; i < sceneColors.length; i++) {
        let col = sceneColors[sceneIndex - 1]
        scene.background = col;
        scene.fog.color = col;
    }


}

function SwitchMusic() {
    if (sceneIndex == 1)
        LoadMusic("/audio/BAYNK-1min.mp3")
    if (sceneIndex == 2)
        LoadMusic("/audio/BAYNK-1min.mp3")
    if (sceneIndex == 3)
        LoadMusic("/audio/BAYNK-1min.mp3")
    if (sceneIndex == 4)
        LoadMusic("/audio/BAYNK-1min.mp3")
    if (sceneIndex == 5)
        LoadMusic("/audio/BAYNK-1min.mp3")
    if (sceneIndex == 6)
        LoadMusic("/audio/BAYNK-1min.mp3")
    if (sceneIndex == 7)
        LoadMusic("/audio/BAYNK-1min.mp3")
    if (sceneIndex == 8)
        LoadMusic("/audio/BAYNK-1min.mp3")
    if (sceneIndex == 9)
        LoadMusic("/audio/BAYNK-1min.mp3")
    if (sceneIndex == 10)
        LoadMusic("/audio/BAYNK-1min.mp3")
    if (sceneIndex == 11)
        LoadMusic("/audio/BAYNK-1min.mp3")

}
//////// TIMERS

let recycleSecondsToWait = 0.5;
var recycleSecond = 0;

RecycleTimer();

function RecycleTimer(now) {
    if (!recycleSecond || now - recycleSecond >= recycleSecondsToWait * 1000) {
        recycleSecond = now;

        RecycleObjects();
    }
    requestAnimationFrame(RecycleTimer);
}
ResetTimer();

var last = 0;

function ResetTimer(now) {
    if (!last || now - last >= 2 * 1000) {
        last = now;

        ApplyRandomMovementToCubes(cubeCloud)
        // ApplyRandomRotationToCubes()
    }
    requestAnimationFrame(ResetTimer);
}

UVTimer();
let secondsToWait = 0.5;
var lastSecond = 0;

function UVTimer(now) {
    if (!lastSecond || now - lastSecond >= secondsToWait * 1000) {
        lastSecond = now;
        //  ApplyObjectVisbility();
        //  ApplyRandomUVCoordinates();
        RaycastForObstacles();
        //   ApplyRandomLocation();
        // ApplyRandomRotationToCubes();
    }
    requestAnimationFrame(UVTimer);
}

SpawnObjectsTimer();
let spawnSecondsToWait = 10;
var spawnSecond = 0;

function SpawnObjectsTimer(now) {
    if (!spawnSecond || now - spawnSecond >= spawnSecondsToWait * 1000) {
        spawnSecond = now;
    }
    requestAnimationFrame(SpawnObjectsTimer);
}

AnimateObjectsTimer();
let animateSecondsToWait = 1;
var animateSecond = 0;

function AnimateObjectsTimer(now) {
    if (!animateSecond || now - animateSecond >= animateSecondsToWait * 1000) {
        animateSecond = now;
        ApplyRandomUVCoordinates();
        // ApplyRandomMovementToCubes(loadedRectangles);
    }
    requestAnimationFrame(AnimateObjectsTimer);
}

//////// KEY CONTROLS (for testing)

document.addEventListener(
    "keydown",
    (event) => {
        const keyName = event.key;

        if (keyName === "ArrowLeft") {
            cameraSpeed = 1;
        }
        if (keyName === "ArrowRight") {
            cameraSpeed = 5;


        }
        if (keyName === "ArrowUp") {}
        if (keyName === "ArrowDown") {}
        if (keyName === "Enter") {
            if (allowInput) {
                CreateNewEndCard();
            }

        }
    },
    false
);

document.addEventListener(
    "keyup",
    (event) => {
        const keyName = event.key;

        if (keyName === "ArrowRight" || keyName === "ArrowLeft") {
            cameraSpeed = 0.1;
        }
    },
    false
);

//////// INTERACTIVES ie listening for click

var raycaster = new THREE.Raycaster();
var mousePos = new THREE.Vector2();

function onDocumentMouseDown(event) {
    event.preventDefault();

    mousePos.x = (event.clientX / canvas.clientWidth) * 2 - 1;
    mousePos.y = -(event.clientY / canvas.clientHeight) * 2 + 1;

    raycaster.setFromCamera(mousePos, camera);

    var intersects = raycaster.intersectObjects(endcards);

    if (allowInput) {
        if (intersects.length > 0) {
            intersects[0].object.callback();
        }

        var intersectAll = raycaster.intersectObjects(scene.children);
        if (intersectAll.length > 0) {
            if (intersectAll[0].object.name == "Intro cube") {

                InitializeExperience();
                $("html, body").css("cursor", "default");
            } else {

            }

        }
    }

}

window.addEventListener("click", onDocumentMouseDown, false);

function onMouseMoving(event) {
    event.preventDefault();
    mousePos.x = (event.clientX / canvas.clientWidth) * 2 - 1;
    mousePos.y = -(event.clientY / canvas.clientHeight) * 2 + 1;
    raycaster.setFromCamera(mousePos, camera);
    var intersects = raycaster.intersectObjects(scene.children);
    if (intersects.length > 0) {
        if (intersects[0].object.name == "Intro cube") {
            intersects[0].object.callback();
            $("html, body").css("cursor", "pointer");
        } else {
            $("html, body").css("cursor", "default");
        }
        if (intersects[0].object.name == "BG Plane") {
            intersects[0].object.callback();
            $("html, body").css("cursor", "default");
        }
    }
}

window.addEventListener("mousemove", onMouseMoving, false);


//////// POST PROCESSING

//////// THE LOOP

const clock = new THREE.Clock();

const tick = (time) => {
    window.requestAnimationFrame(tick);

    MoveCamera();
    MovingMouse();
    ChangeCubeColorsOnHover();
    //controls.update();
    CameraBarrellRoll();
    renderer.render(scene, camera);

    TWEEN.update(time);

    RaycastForObstacles()
    ApplyUVScrolling()
};
tick();

function RaycastForObstacles() {
    ////// THIS DESTORYS ANY OBJECT AHEAD OF US

    let positions = [new THREE.Vector3(camera.position.x - .1, camera.position.y, camera.position.z),
        new THREE.Vector3(camera.position.x, camera.position.y - .1, camera.position.z),
        new THREE.Vector3(camera.position.x + .1, camera.position.y, camera.position.z),
        new THREE.Vector3(camera.position.x, camera.position.y + .1, camera.position.z),
    ]

    posIndex++

    if (posIndex > positions.length - 1) {
        posIndex = 0
    }
    const newRaycaster = new THREE.Raycaster();
    // Cast a ray
    const rayOrigin = positions[posIndex];
    const rayDirection = new THREE.Vector3(0, 0, -1000);
    rayDirection.normalize();

    newRaycaster.set(rayOrigin, rayDirection);

    const objectsToTest = segments;
    const intersects = newRaycaster.intersectObjects(objectsToTest);


    for (let i = 0; i < intersects.length; i++) {
        if (intersects[i] != null) {

            let newMat = intersects[i].object.material.clone()
            newMat.transparent = true;
            intersects[i].object.material = newMat;

            gsap.to(intersects[i].object.material, {
                duration: .5,
                delay: 0,

                opacity: 0,
            });

            //////////////////////////////////////////////////////////////////////////////////////////////////
        }
    }
}

function randomizeValue() {
    var value = (1 + 10e-16) * Math.random();

    if (value > 1.0) {
        return 1.0;
    }

    return value;
}

function GetRandomFloat(min, max) {
    if (max == null) {
        max = min == null ? Number.MAX_VALUE : min;
        min = 0.0;
    }

    if (min >= max) {
        throw new Error("Incorrect arguments.");
    }

    return min + (max - min) * randomizeValue();
}


function LoadMusic(musicPath) {
    audioLoader.load(musicPath, function (buffer) {
        sound.setBuffer(buffer);
        sound.setLoop(true);
        sound.setVolume(0.5);
        sound.play();
        currentlyPlaying = sound;


        let tween = new TWEEN.Tween(listener.gain.gain).to({
                    value: 1,
                },
                1
            )

            .start()
    });
}


function ApplySceneParameters() {

    camera.rotation.z = 0

    if (sceneIndex == 1 || sceneIndex == 12) {
        cameraSpeed = .2;
        enableAnimationMovement = true;
        enableBarrellRoll = false
        enableObjectUVScrolling = true
        enableCorridorUVScrolling = true
        uvScrollSpeed = 0.001
        animationMovementDistance = 1
        corridorUVSizeX = 10
        corridorUVSizeY = 2
        objectUVSize = 1
        scrollOnXAxis = true;
        console.log("applying scene 1")
        enableUVSwitching = true;
        CreateText("Track 1")


    } else if (sceneIndex == 2) {
        cameraSpeed = 1;
        enableAnimationMovement = false;
        enableBarrellRoll = false
        uvScrollSpeed = 0.001
        animationMovementDistance = 1
        objectUVSize = 1
        scrollOnXAxis = false;
        corridorUVSizeX = 10
        corridorUVSizeY = 2
        console.log("applying scene 2")
        CreateText("Track 2")
        enableUVSwitching = false;
    } else if (sceneIndex == 3) {
        cameraSpeed = .1;
        enableAnimationMovement = true;
        enableBarrellRoll = true;
        uvScrollSpeed = 0.001
        animationMovementDistance = 6
        objectUVSize = 1
        scrollOnXAxis = false;
        CreateText("Track 3")
        enableUVSwitching = true;
    } else if (sceneIndex == 4) {
        cameraSpeed = 1;
        enableAnimationMovement = true;
        enableBarrellRoll = false;
        uvScrollSpeed = 0.001
        objectUVSize = 1
        scrollOnXAxis = true;
        CreateText("Track 4")
        enableUVSwitching = false;
    } else if (sceneIndex == 5) {
        cameraSpeed = .5;
        enableAnimationMovement = true;
        enableBarrellRoll = false
        uvScrollSpeed = 0.001
        objectUVSize = 1
        CreateText("Track 5")
        enableUVSwitching = true;
    } else if (sceneIndex == 6) {
        cameraSpeed = 1;
        enableAnimationMovement = true;
        enableBarrellRoll = false
        animationMovementDistance = 1
        scrollOnXAxis = false;
        objectUVSize = 1
        CreateText("Track 6")
        enableUVSwitching = false;
    } else if (sceneIndex == 7) {
        cameraSpeed = 2;
        enableAnimationMovement = true;
        enableBarrellRoll = false
        objectUVSize = 1
        scrollOnXAxis = true;
        CreateText("Track 7")
        enableUVSwitching = true;
    } else if (sceneIndex == 8) {
        cameraSpeed = .1;
        enableAnimationMovement = true;
        enableBarrellRoll = false
        scrollOnXAxis = false;
        objectUVSize = 1
        CreateText("Track 8")
        enableUVSwitching = false;
    } else if (sceneIndex == 9) {
        cameraSpeed = .1;
        enableAnimationMovement = true;
        enableBarrellRoll = false
        objectUVSize = 1
        CreateText("Track 9")
        enableUVSwitching = true;
    } else if (sceneIndex == 10) {
        cameraSpeed = .5;
        enableAnimationMovement = true;
        enableBarrellRoll = false
        scrollOnXAxis = false;
        objectUVSize = 1
        enableUVSwitching = false;
        CreateText("Track 10")
    } else if (sceneIndex == 11) {
        cameraSpeed = .11;
        enableAnimationMovement = true;
        enableBarrellRoll = false
        scrollOnXAxis = true;
        objectUVSize = 1
        CreateText("Track 11")
        enableUVSwitching = true;
    }

    for (let i = 0; i < loadedCorridors.length; i++) {
        //  loadedCorridors[i].material.map = corridorTextures[sceneIndex - 1];
        loadedCorridors[i].material.map.repeat.set(corridorUVSizeX, corridorUVSizeY);

    }
}

function ApplyOverallRotation(rotX) {
    for (let i = 0; i < segments.length; i++) {
        segments[i].rotation.x = rotX

    }
}

/**
 * Fonts
 */
const fontLoader = new THREE.FontLoader();
let text
CreateText("intro")

function CreateText(textString) {

    if (text != null) {
        text.visible = false
    }
    fontLoader.load("/fonts/helvetiker_bold.typeface.json", (font) => {
        // Material
        const material = new THREE.MeshMatcapMaterial({});
        material.color = new THREE.Color(0xffffff);
        // Text

        const textGeometry = new THREE.TextBufferGeometry(textString, {
            font: font,
            size: 0.002,
            height: 0,
            curveSegments: 6
        });

        textGeometry.center();


        text = new THREE.Mesh(textGeometry, material);
        scene.add(text);
        camera.add(text)
        text.position.set(0, -.027, -0.1);
        text.scale.set(1, 1, 1);


    });
}

function CreateTitle() {

    if (text != null) {
        text.visible = false
    }
    fontLoader.load("/fonts/helvetiker_bold.typeface.json", (font) => {
        // Material
        const material = new THREE.MeshMatcapMaterial({});
        material.color = new THREE.Color(0xffffff);
        // Text

        const textGeometry = new THREE.TextBufferGeometry("BANYK", {
            font: font,
            size: 0.002,
            height: 0,
            curveSegments: 6
        });

        textGeometry.center();


        text = new THREE.Mesh(textGeometry, material);
        scene.add(text);
        //   camera.add(text)
        text.position.set(0, -.027, -0.1);
        text.scale.set(1, 1, 1);


    });
}